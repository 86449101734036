import React, { Component } from 'react'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/service/banner.png'
import CaseStudyComponent from '../components/services/CaseStudyComponent'
import TestimonialComponent from '../components/services/TestimonialComponent'
import FirstComponent from '../components/salesforcetesting/FirstComponent'
import TheSolution from '../components/salesforcetesting/TheSolution'
import TheOfferings from '../components/salesforcetesting/TheOfferings'
import TheDifferences from '../components/salesforcetesting/TheDifferences'
import TheChallenges from '../components/salesforcetesting/TheChallenges'
class SalesforceTestingContainer extends Component {
	render() {
		return (
			<>
				<BannerImage
					activeTab="Salesforce Testing & Test Automation"
					bannerImage={banner}
					text="Complex salesforce 
					implementation needs a 
					team with knowledge and 
					experience." />
				<FirstComponent />
				<TheChallenges/>
				<TheSolution />
				<TheOfferings />
				<TheDifferences />
				<CaseStudyComponent />
				<TestimonialComponent />
			</>
		)
	}
}

export default SalesforceTestingContainer