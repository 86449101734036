import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
const TheOfferings = () => {
	return (
		<>
			<Container className="carousel-columns mt-5">
				<div className="d-flex justify-content-start align-items-center">
					<div className="line"></div>
					<div>
						<h6 className="carousel-section-subheading">Our Offering</h6>
					</div>
				</div>
				<h2>END-TO-END SALESFORCE TESTING SERVICES</h2>
			</Container>
			<Container>
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							GTEN Technologies can help you navigate through the most difficult
							digital challenges with our expertise in Salesforce testing.
						</p>
					</Col>
				</Row>
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							Our Services and tool expertise include:
						</p>
						<ul>
							<li className="text-list">Salesforce Scenario Definition.</li>
							<li className="text-list">Tools Evaluations & Recommendation.</li>
							<li className="text-list">Test Automation Framework Implementation.</li>
							<li className="text-list">Test Automation Scripting, Execution and Analysis.</li>
							<li className="text-list">Automated Regression Testing.</li>
							<li className="text-list">Integration test strategy for Salesforce with other enterprise systems.</li>
						</ul>
					</Col>

				</Row>
			</Container>
		</>
	)
}

export default TheOfferings