import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import SalesforceTestingContainer from '../containers/SalesforceTestingContainer'

const SalesforceTesting = (props) => (
	<Layout
		pageInfo={{ pageName: "Salesforce Testing" }}
		styleClass="home-page "
		location={props.location.pathname}>
		<SEO title="Salesforce Testing" />
		<SalesforceTestingContainer />
	</Layout>
)

export default SalesforceTesting
