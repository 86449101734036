import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const FirstComponent = () => {
	return (
		<>
			<Container className="pt-3 pb-5 carousel-columns">

				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							Over 150,000 businesses worldwide rely on the Salesforce platform to help them
							become more agile and customer-focused. A key driver in Salesforce’s popularity
							is its extensibility. Not only is it easy for organizations to customize their own
							Salesforce solutions, but Salesforce has a thriving AppExchange marketplace
							with over 4,000 applications that customers can download to extend their
							Salesforce solutions.
						</p>
					</Col>
				</Row>
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							The challenge for Salesforce teams is that most Salesforce testing is performed
							manually, which leads to bottlenecks that slow down innovation. A recent GitLab
							survey confirms what you may already see in your own organization – that
							testing is the #1 bottleneck in the software delivery process.
						</p>
					</Col>
				</Row>
				<hr />
			</Container>
		</>
	);
};

export default FirstComponent;