import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const TheChallenges = () => {
	return (
		<Container fluid>
			<Container className="carousel-columns">
				<div className="d-flex justify-content-start align-items-center">
					<div className="line"></div>
					<div>
						<h6 className="carousel-section-subheading">The Challenges</h6>
					</div>
				</div>
				<h2>SALESFORCE IMPLEMENTATIONS IF GONE WRONG CAN CAUSE SERIOUS DAMAGE</h2>
			</Container>
			<Container className="pb-5">
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							Salesforce’s cloud-based architecture and seamless ability to integrate with
							existing ecosystem apps in an enterprise make it a most sought-after solution for
							innovation. Thanks to this Salesforce quickly become an organization ‘s most
							critical data and processes, any update, extension, or customization must be
							thoroughly tested.
						</p>
					</Col>
				</Row>
				<Row className="pt-2 px-3">
					<Col>
						<div className="initial-divaragrapgh">
							Challenges for testing are:
						</div>
						<ul>
							<li className="text-list">Frequent updates on the platform from Salesforce, third party app providers and organizations own salesforce team.</li>
							<li className="text-list">Integrations with existing enterprise apps.</li>
							<li className="text-list">More complex environments include multiple channels, devices, and delivery formats.</li>
							<li className="text-list">Complex business rules and cross validations</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</Container>
	)
}

export default TheChallenges